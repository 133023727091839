var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-autocomplete',{ref:"input",class:_vm.requiredClass,attrs:{"data":_vm.filteredCountries,"field":"name","open-on-focus":"","type":_vm.messageType,"keep-first":"","expanded":"","icon":_vm.countryFocused && !_vm.type ? ("flag-icon flag-icon-" + (_vm.value ? _vm.value.toLowerCase() : 'un')) : null,"icon-pack":"flag-icon"},on:{"blur":_vm.sendblur,"focus":function($event){_vm.countryFocused = true; _vm.select($event);},"select":function (option) {
      if (option && option.code) {
        this$1.selectedOptionName = option.name;
        this$1.selectedOptionCode = option.code;
        _vm.countrySearch = option.name;
        _vm.$emit('input', option.code);
      }
    }},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{class:("flag-icon flag-icon-" + (props.option.code.toLowerCase()))}),_vm._v("\n    "+_vm._s(props.option.name)+"\n    "),(_vm.phone)?_c('span',[_vm._v("\n      "+_vm._s(props.option.exPhone ? props.option.exPhone.split(" ")[0] : "")+"\n    ")]):_vm._e()]}}]),model:{value:(_vm.countrySearch),callback:function ($$v) {_vm.countrySearch=$$v},expression:"countrySearch"}},[(_vm.showCodeFragmentMark)?_c('span',[_vm._v("[cfm: components/VfaCountrySelector.vue - 001]")]):_vm._e(),_vm._v(" "),_c('template',{slot:"header"},[_c('label',{staticClass:"is-size-6 has-text-grey",attrs:{"for":"country"}},[_c('At',{attrs:{"s":_vm.dict.E07}})],1)]),_vm._v(" "),_c('template',{slot:"empty"},[_vm._v("\n    "+_vm._s(_vm.getDictWP(_vm.dict.E08, { typed: _vm.countrySearch }))+"\n  ")]),_vm._v(" "),_vm._v(" "),(_vm.showCodeFragmentMark)?_c('span',[_vm._v("[cfm: components/VfaCountrySelector.vue - 002]")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }