//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getDictWP } from '~/utils/butterUtils'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'CountrySelector',
  props: {
    value: String,
    phone: Boolean,
    type: String,
    messageType: String,
    requiredClass: Object,
    dict: Object
  },
  data () {
    return {
      countrySearch: '',
      countryFocused: false,
      selectedOptionName: '',
      selectedOptionCode: ''
    }
  },
  computed: {
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    filteredCountries () {
      if (
          this.allowedCountries.find(
            x => x.name.toLowerCase() === this.countrySearch.toLowerCase()
        )
      ) {
        return [
          this.allowedCountries.find(
            x => x.name.toLowerCase() === this.countrySearch.toLowerCase()
          )
        ].concat(
          this.allowedCountries.filter(
            (country, index) =>
              country.name.toLowerCase() !== this.countrySearch.toLowerCase() ||
              index > 6
          )
        )
      } else if (this.countrySearch) {
        return this.allowedCountries.filter(option => {
          return (
            option.name
              .toString()
              .toLowerCase()
              .includes(this.countrySearch.toLowerCase()) ||
            option.code
              .toString()
              .toLowerCase()
              .includes(this.countrySearch.toLowerCase()) ||
            (option.exPhone &&
              option.exPhone
                .split(' ')[0]
                .replace('+', '')
                .includes(this.countrySearch.replace('+', '')))
          )
        })
      } else return this.allowedCountries
    },
    mustBeEmail () {
      return false
    },
    allowedCountries () {
      return this.type === 'abrAdr'
        ? this.countries.filter(x => !/^AS|GU|PR|UM|VI$/.test(x.code))
        : this.countries
    },
    ...mapState('data', ['countries'])
  },
  watch: {
    value (val) {
      // console.log("VfaCountrySelector  watch  value (val)="+val)
      if (val) {
        this.countrySearch = this.countries.find(x => x.code === val).name
      }
      this.updateCountryData(val)
    }
  },
  methods: {
    select (event) {
      this.selectedOptionName = ''
      this.selectedOptionCode = ''
      event.target.setSelectionRange(0, 9999)
      this.$emit('focus')
    },
    sendblur () {
      /*
        2024-03-28 John Yee
        The voter may simply enter a country name in the input box and then mouse out to another field instead of clicking on a country or pressing enter.
        This type of action does not select/change the country and the existing pre-populated country remains selected.
        Hence, add the code for the select event to the blur event so that the country ISO code is sent to the parent.

        example:
        (1) voter in France
        (2) on launch the country field is pre-populated with "France" because an IP address lookup indicates the voter is in France
        (3) voter types "Zambia" in the country field
        (4) voter uses the mouse to move to the city field; voter has not pressed Enter or Tab or clicked on "Zambia" in the drop down list
        In this scenario the selected country remains "France."  The country does not change to "Zambia" because Zambia's country code has not been sent to the parent component.
        Pressing Enter or pressing Tab or clicking on "Zambia" would have triggered a "select" event which is programmed to send the country ISO code to the parent.
      */
      /*
        2024-03-28 John Yee
        reason for using setTimeout - onblur event triggers before onclick

        If the voter clicks on a country in the drop down list, then onblur does not capture the selected country data immediately
        because the onclick has not been processed yet.  So, wait a bit to allow the onclick handler to capture the selected country
        and then pass the data to the onblur handler.
      */
      if (this.filteredCountries.length>0) {
        this.countryFocused = false

        setTimeout(() => {
          let selectedCountryName
          let selectedCountryCode
          if (this.selectedOptionName) {
            selectedCountryName = this.selectedOptionName
            selectedCountryCode = this.selectedOptionCode
          } else {
            selectedCountryName = this.filteredCountries[0].name
            selectedCountryCode = this.filteredCountries[0].code
          }
          this.countrySearch = selectedCountryName

          this.$emit('blur', selectedCountryCode)
          this.$emit('input', selectedCountryCode)
        }, 400)
      } else {
        this.$emit('blur', 'noCountrySelected')
        this.$emit('input', 'noCountrySelected')
      }
    },
    getDictWP(dictItem, paramObj) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The "getDictWP(dictItem, paramObj)" in the return statement is really
       * the function getDictWP that is imported from ~/utils/butterUtils.js
       * 
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictWP(dictItem, paramObj)
    },
    ...mapActions('data', ['updateCountryData'])
  },
  mounted () {
    if (this.value) {
      this.countrySearch =
        this.countries && this.countries.find(x => x.code === this.value)
          ? this.countries.find(x => x.code === this.value).name
          : ''
      this.updateCountryData(this.value)
    }
  }
}
